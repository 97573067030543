.logo{
  width: 100px;
  height: 100px;
 margin-left: 50%;
 transform: translateX(-50%);
 /* margin-bottom: 20px; */
}

.instaIcon {
  /* position: absolute;
font-size: 2em;
left: 10vw;
top: 10px; */
/* text-align: center; */
font-size: 2em;
}

.twitIcon {
  margin-left: 20px;
  font-size: 2em;
}

.socials {
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  /* border: 1px solid red; */
  margin-top: 20px;
  /* margin-bottom: 20px; */
}

a:link {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}